import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, Container, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import React, { useState, useEffect } from 'react';
import { CREATE_OR_UPDATE_LOGS } from '../graphql/createOrUpdateLogs';
import { GET_WELDERS } from '../graphql/getWelders';
import { GET_WELD } from '../graphql/getWeld';

const WeldsLogForm = () => {
  const navigate = useNavigate();
  const [projectId] = useState(Number(useParams().projectId));
  const [weldId] = useState(Number(useParams().weldId));
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [baseTotalPercentage, setBaseTotalPercentage] = useState(0)
  const [weldNo, setWeldNo] = useState(0)
  const [weld, setWeld] = useState({});
  const { data: welders, loading: queryLoading, error: queryError } = useQuery(GET_WELDERS);
  const [createOrUpdateLogs, { loading: mutationLoading, error: mutationError }] = useMutation(CREATE_OR_UPDATE_LOGS, {
    onCompleted: (data) => {
      console.log("Mutacja zakończona sukcesem:", data);
      navigate(`/welds/${Number(projectId)}`);
    },
    onError: (error) => {
      console.error("Błąd podczas mutacji:", error);
    },
  });
  const [getWeld] = useLazyQuery(GET_WELD);

  useEffect(() => {
    getWeld({
      variables: {
        weldId: weldId,
      },
    }).then((response) => {
      setWeld(response.data.weld)
      setBaseTotalPercentage(response.data.weld.welding_progress || 0)
      setWeldNo(response.data.weld.no || 0)
    })
  }, [weldId, getWeld])

  const [error, setError] = useState(null);

  if (error) {
    console.error(error)
  }

  const welderList = welders?.people ? welders.people.list.map((welder) => ({ id: welder.id, name: welder.name })) : [];

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const [inputs, setInputs] = useState([{ welder: "", welderId: "", percentage: '', date: getTodayDate(), note: '' }]);
  const [successMessage, setSuccessMessage] = useState('');

  const [validationError, setValidationError] = useState('');

  const handleAddInput = () => {
    const totalPercentage = inputs.reduce((sum, item) => sum + (parseFloat(item.percentage) || 0), 0);

    if (totalPercentage >= 100) {
      setError("The value cannot exceed 100.");
      return;
    }
    setInputs((prevInputs) => [
      ...prevInputs,
      { welder: "", welderId: "", percentage: '', date: prevInputs[0].date, note: prevInputs[0].note },
    ]);
    setError(null);
  };

  const handleRemoveInput = (index) => {
    setInputs((prevInputs) => prevInputs.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const values = [...inputs];

    if (name === 'welder') {
      const selectedWelder = welderList.find((welder) => welder.id === value);
      if (selectedWelder) {
        values[index].welder = selectedWelder.name;
        values[index].welderId = selectedWelder.id;
      }
    } else {
      values[index][name] = value;
    }

    updateTotalPercentage(values);
    setValidationError('');
    setInputs(values);
  };

  const handleChangeDateOrNote = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) =>
      prevInputs.map((input) => ({ ...input, [name]: value }))
    );
  };

  const updateTotalPercentage = (inputs) => {
    const total = baseTotalPercentage + inputs.reduce((sum, item) => sum + (parseFloat(item.percentage) || 0), 0);
    setTotalPercentage(total);
    return total;
  };

  const handleSubmit = async () => {
    setSuccessMessage('');

    try {
      await Promise.all(
        inputs.map((input) => {
          const { welderId, percentage, date, note } = input;
          const percentageNum = parseInt(percentage);
          const calculateComplete = weld?.length * (percentageNum / 100);

          const variablesToSave = {
            person_id: welderId,
            complete: calculateComplete,
            completePercent: percentageNum,
            date,
            note,
            welds_id: [Number(weldId)],
            type: "WlWelds::Logs::Welding",
          }

          return createOrUpdateLogs({
            variables: variablesToSave
          });
        })
      );
      setInputs([{ welder: '', welderId: '', percentage: '', date: getTodayDate(), note: '' }]);
      setSuccessMessage('Zmiany zostały zapisane poprawnie!');
    } catch (error) {
      console.error("Wystąpił błąd podczas wysyłania danych:", error);
    }
  };

  if (queryLoading) return <Box sx={{ width: '100%' }}><LinearProgress /></Box>;
  if (queryError) return <Alert severity="error">Error: {queryError.message}</Alert>;

  return (
    <Container sx={{ paddingTop: 4 }}>
      <Typography variant="h6" gutterBottom>
        Welds Log
      </Typography>
      <Typography variant="h8" gutterBottom>This weld no <b>{weldNo}</b> is completed in <b>{baseTotalPercentage}</b>%</Typography>
      {mutationError && (<Alert severity="error" sx={{ marginTop: 2 }}>Error: {mutationError.message}</Alert>)}
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      {inputs.map((input, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Choose Welder"
              name="welder"
              value={input.welderId || ''}
              fullWidth
              required
              onChange={(e) => handleInputChange(index, e)}
              sx={{ marginTop: 2 }}
            >
              {welderList.map((welder, i) => (
                <MenuItem key={i} value={welder.id}>
                  {welder.name}
                </MenuItem>
              ))} =
            </TextField>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Percentage"
              name="percentage"
              value={input.percentage}
              fullWidth
              required
              onChange={(e) => handleInputChange(index, e)}
              InputProps={{ inputProps: { min: 0, max: 100 } }}
              type="number"
              sx={{
                marginTop: 2,
                // Remove arrows in Chrome, Safari, Edge, Opera
                '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0
                },
                // Remove arrows in Firefox
                '& input[type=number]': {
                  '-moz-appearance': 'textfield'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            {
              totalPercentage <= 100
                ? (<IconButton onClick={handleAddInput} color="primary" sx={{ marginTop: 2 }}><AddIcon /></IconButton>)
                : (<Typography sx={{ color: "red", fontSize: "12px", alignContent: "center" }}> The value cannot exceed 100.</Typography>)
            }

            {
              index !== 0 && (
                <IconButton onClick={() => handleRemoveInput(index)} color="primary" sx={{ marginTop: 2 }}>
                  <RemoveIcon />
                </IconButton>
              )
            }
          </Grid >
        </Grid >
      ))}
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mt: 4 }}>
        <Grid item xs={12} sm={3}>
          <TextField
            type="date"
            label="Date"
            name="date"
            value={inputs[0].date}
            fullWidth
            required
            sx={{ marginTop: 2 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChangeDateOrNote}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            label="Notes"
            name="note"
            value={inputs[0].note}
            fullWidth
            multiline
            rows={4}
            sx={{ marginTop: 4 }}
            onChange={handleChangeDateOrNote}
          />
        </Grid>
        {
          validationError && (
            <Alert severity="error" sx={{ marginTop: 2 }}>
              {validationError}
            </Alert>
          )
        }
        {
          totalPercentage <= 100 && baseTotalPercentage < 100 && <Button
            sx={{ marginTop: 4 }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={mutationLoading}
          >
            Save
          </Button>
        }
      </Box>
    </Container >
  );
};

export default WeldsLogForm;
