
import { useLazyQuery, useQuery } from '@apollo/client';
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight"; // Upewnij się, że to jest poprawny import
import { Box, Button, Container, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeItem2 } from '@mui/x-tree-view/TreeItem2';
import { useTreeItem2Utils } from '@mui/x-tree-view/hooks';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_ELEMENTS } from '../graphql/getElements';

let tmpNextId = 999999999999

function CustomLabel({ children, className, secondaryLabel }) {
    const navigate = useNavigate();

    const handleEditClick = (secondaryLabel) => {
        navigate(`/welds/${secondaryLabel}`);
    };

    return (
        < div className={className} style={{ width: '100%', padding: 4 }}>
            <Typography>{children}
                <Button
                    endIcon={<KeyboardDoubleArrowRightIcon />}
                    variant="outlined" size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleEditClick(secondaryLabel.id);
                    }}
                    sx={{ marginRight: "0px", float: "right" }}
                >
                    Welds
                </Button>
            </Typography>
        </div >
    );
}

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const { publicAPI } = useTreeItem2Utils({
        itemId: props.itemId,
        children: props.children,
    });

    const item = publicAPI.getItem(props.itemId);

    return (
        <TreeItem2
            {...props}
            ref={ref}
            slots={{
                label: CustomLabel,
            }}
            slotProps={{
                label: { secondaryLabel: item },
            }}
        />
    );
});

const Elements = () => {

    const [projectId] = useState(Number(useParams().projectId))
    const { data, loading, error } = useQuery(GET_ELEMENTS, {
        variables: { projectId: projectId },
    });
    const [getElements] = useLazyQuery(GET_ELEMENTS);

    let [tree, setTree] = useState([]);
    let element = {}

    if (loading) return <Box sx={{ width: '100%' }}><LinearProgress /></Box>;
    if (error) return `Error! ${error.message}`;
    if (data && Number(tree.length) === Number([])) {
        data?.elements?.list.forEach((item) => (
            tree = createNodeTree(item, tree)
        ))
    }

    function createNodeTree(item, tree) {
        return Number(item.children_count) > 0 ?
            [...tree, {
                id: item.id + '', label: item.name, children: [{ id: tmpNextId++ + '', label: "" }]
            }] :
            [...tree, { id: item.id + '', label: item.name, children: [] }]
    }

    function getSubMenuItem(subMenuItems, id) {
        if (subMenuItems.length > 0) {
            subMenuItems.forEach((item) => {
                if (Number(item.id) === Number(id)) {
                    element = item
                } else if (item.children?.length > 0) {
                    return getSubMenuItem(item.children, id)
                }
            })
        }
        return element
    };

    function handleTree(elementId) {
        const childTree = [...tree];
        let artwork = getSubMenuItem(childTree, elementId)
        if (artwork.children.length > 0) {
            getElements({ variables: { projectId: Number(elementId) } }).then((response) => {
                let nextChildTree = []
                response.data?.elements?.list.forEach((item) => (
                    nextChildTree = createNodeTree(item, nextChildTree)
                ))
                if (artwork.children[0].label === '') {
                    artwork.children = nextChildTree
                }
                setTree(childTree);
            })
        }
    }

    return (
        <Container sx={{ paddingTop: 4 }}>
            <Typography variant="h5" gutterBottom>Elements tree in Project</Typography>
            < RichTreeView
                items={tree}
                onItemClick={(event, itemId) => handleTree(itemId,)}
                slots={{ item: CustomTreeItem }}
            />
        </Container>
    )

}

export default Elements;
