import React from 'react';
import { Navigate } from 'react-router-dom';
import isTokenExpired from './isTokenExpired'

const useAuth = () => {
  const authToken = localStorage.getItem('auth_token');
  return authToken !== null;
};

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useAuth();

  if (!isAuthenticated || isTokenExpired(localStorage.getItem('auth_token'))) {
    localStorage.removeItem('auth_token');
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute;
