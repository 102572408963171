import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Box, Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';
import weldingo from '../images/white_logo_transparent.png';

export default function AppMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const email = localStorage.getItem('email');
  const projectId = useParams().projectId
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBack = () => {
    switch (location.pathname.match(/\/\w+/)[0]) {
      case '/project-list':
        navigate('/');
        break;
      case '/welds':
        navigate('/project-list');
        break;
      case '/weld':
        console.log('test')
        navigate(`/welds/${Number(projectId)}`);
        break;
      default:
        navigate(`/`);
    }
  };


  const handleLogout = () => {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('refresh_token');
    window.location.href = '/';
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px 16px',
          backgroundColor: 'primary.main',
          color: 'white',
        }}
      >
        {location.pathname !== 'project-list' && (
          <IconButton onClick={handleBack} color="inherit">
            <KeyboardBackspaceIcon />
          </IconButton>
        )}
        <a href="/" rel="noopener noreferrer">
          <img
            src={weldingo}
            alt="Weldingo"
            style={{ height: '80px' }}
          />
        </a>
        <IconButton color="inherit" onClick={handleMenuOpen}>
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {email && (
            <MenuItem>
              <Avatar sx={{ marginRight: 1 }}>{email[0].toUpperCase()}</Avatar>
              <Typography>{email}</Typography>
            </MenuItem>
          )}
          <hr></hr>
          <MenuItem onClick={handleLogout}>
            <Button variant="text" color="inherit">Logout</Button>
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
}
