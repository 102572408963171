import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LoginPage from "./pages/LoginPage";
import ProjectElementsPage from "./pages/ProjectElementsPage";
import ProjectListPage from "./pages/ProjectListPage";
import WeldsLogFormPage from "./pages/WeldsLogFormPage";
import WeldsPage from "./pages/WeldsPage";
import PrivateRoute from './utils/PrivateRoute';
import RefreshToken from './components/RefreshToken';


function App() {
  return (
    < Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }} >
      <RefreshToken />
      < Routes >
        < Route path="/login" element={< LoginPage />} />
        < Route path="/project-list" element={< PrivateRoute > <ProjectListPage /></PrivateRoute >} />
        < Route path="/project/:projectId" element={< PrivateRoute > <ProjectElementsPage /></PrivateRoute >} />
        < Route path="/welds/:projectId" element={< PrivateRoute > <WeldsPage /></PrivateRoute >} />
        < Route path="/weld/:projectId/:weldId" element={<PrivateRoute><WeldsLogFormPage /></PrivateRoute>} />
        < Route path="*" element={< Navigate to="/project-list" />} />
      </Routes >
    </Router >
  );
}

export default App;
