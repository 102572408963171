import { gql } from '@apollo/client';

export const GET_WELD = gql`
  query GetWeld($weldId: ID!) {
    weld(weld_id: $weldId) {
      joint_type_id
      length
      no
      project {
        id
      }
      status
      weld_type_id
      welding_progress
    }
  }
`;
