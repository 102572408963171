import { gql } from '@apollo/client';

export const CREATE_OR_UPDATE_LOGS = gql`
  mutation CreateOrUpdateLogs(
    $completePercent: Int!,
    $complete: Int,
    $log_data: String
    $note: String
    $person_id: ID!
    $type: String!
    $welds_id: [Int]!
    $date: String
  ) {
    createOrUpdateLogs(
      complete_percent: $completePercent  
      complete: $complete
      log_data: $log_data
      note: $note
      person_id: $person_id
      type: $type
      welds_id: $welds_id
      date: $date
    ) {
      logs {
        errors
        log_data
        type
        weld_failed
        complete
        complete_percent
        note
        date
        created_at
        updated_at
      }
    }
  }
`;
