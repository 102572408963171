
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import isTokenExpired from '../utils/isTokenExpired'
import { REFRESH_USER_TOKEN_MUTATION } from '../graphql/authenticateUserMutations';

const RefreshToken = () => {

  const [token] = useState(localStorage.getItem('auth_token'));
  const [refresh_token] = useState(localStorage.getItem('refresh_token'));
  const navigate = useNavigate();

  const [getRefreshToken] = useMutation(REFRESH_USER_TOKEN_MUTATION, {
    variables: { refresh_token: refresh_token },
    retry: false
  });

  useEffect(() => {
    if (!isTokenExpired(refresh_token) && isTokenExpired(token)) {
      getRefreshToken().then(responseValue => {
        localStorage.setItem('auth_token', responseValue.data.refreshUserToken.token);
        localStorage.setItem('refresh_token', responseValue.data.refreshUserToken.refresh_token);
        navigate(-1);
      }).catch(error => {
        console.error(error)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!isTokenExpired(token)) {
    return true;
  }

  if (isTokenExpired(refresh_token)) {
    return true;
  }

  return true
}

export default RefreshToken;
