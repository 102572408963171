import { gql } from '@apollo/client';

export const GET_WELDS = gql`
  query GetWelds($projectId: ID!, $filters: WeldFilterInput, $page: Int, $size: Int) {
    welds(project_id: $projectId, page: $page, size: $size, sort_column: "id", sort_direction: "ASC", filters: $filters) {
      list {
        id
        no
        welding_progress
        weld_type {
          name
        }
        length
        material_1 {
          name
        }
        material_2 {
          name
        }
        wps {
          no
        }
        extra_data
        status
      }
      metadata {
        current_page
        next_page
        prev_page
        total_count
        total_pages
      }
    }
  }
`;
