import { gql } from '@apollo/client';

export const GET_WELDS_FILTERS = gql`
  query GetWeldsFilters($base_material_1: String, $base_material_2: String, $project_id: Int!, $size: Int) {
    joint_types {
      name
      id
    }
    type_of_welds {
      name
      id
    }
    base_materials_1: base_materials(q: $base_material_1, size: $size, project_id: $project_id) {
      id
      designation_name
    }
    base_materials_2: base_materials(q: $base_material_2, size: $size, project_id: $project_id) {
      id
      designation_name
    }  
    wps_list(size: 100){
      list {
        id
        no
      }
    }
    available_weld_statuses {
      code
      name
    }
  }
`;
