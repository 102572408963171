import { gql } from '@apollo/client';

export const UserPartsFragment = gql`
  fragment UserParts on Authenticate {
    token
    refresh_token
    errors
    user {
      email
      id
      last_sign_in_at
    }
  }
`

export const SIGN_IN_MUTATION = gql`
  mutation SignInUser($email: String!, $password: String!) {
    signInUser(email: { email: $email, password: $password }) {
      ...UserParts
    }
  }
  ${UserPartsFragment}
`;

export const REFRESH_USER_TOKEN_MUTATION = gql`
  mutation RefreshUserToken($refresh_token: String!) {
    refreshUserToken(refresh_token: $refresh_token) {
      ...UserParts
    }
  }
  ${UserPartsFragment}
`;
